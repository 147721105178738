export function injectRecaptcha() {
  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_SITE_KEY}`
  script.innerHTML = `
      (function(){
        var w=window,C='___grecaptcha_cfg',cfg=w[C]=w[C]||{},N='grecaptcha';
        var gr=w[N]=w[N]||{};gr.ready=gr.ready||function(f){(cfg['fns']=cfg['fns']||[]).push(f);};
        w['__recaptcha_api']='https://www.google.com/recaptcha/api2/';
        (cfg['render']=cfg['render']||[]).push('onload');
        w['__google_recaptcha_client']=true;
        var d=document,po=d.createElement('script');
        po.type='text/javascript';
        po.async=true;
        po.src='https://www.gstatic.com/recaptcha/releases/sNQO7xVld1CuA2hfFHvkpVL-/recaptcha__en.js';
        po.crossOrigin='anonymous';
        po.integrity='sha384-Zkv9F02+yghSXAFFLPZt/TKDOCrO9vWe4j0Nx8+F2CXcAqAqpPE66Q9PuIf0y2a3';
        var e=d.querySelector('script[nonce]'),n=e&&(e['nonce']||e.getAttribute('nonce'));
        if(n){po.setAttribute('nonce',n);}
        var s=d.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(po, s);
      })();
    `

  document.head.append(script)
}
