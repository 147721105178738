
import { useRouter } from '@nuxtjs/composition-api';
import { computed, nextTick, onBeforeMount, onMounted, ref, shallowRef, watch } from '@vue/composition-api';
import { useResizeStore } from '~/store/resize';
import type Brand from '~/type/Brand';
import type Category from '~/type/Category';
interface Props {
  list: CategoryMenu[];
  error?: boolean;
  menuState?: boolean;
  isProductPage?: boolean;
  cityCode?: string;
}
interface CategoryMenu extends Category {
  brands: Brand[];
  activeCurrent: boolean;
  isActive: boolean;
  isActiveImage: boolean;
  children: CategoryMenu[];
}
interface MenuAimInstance {
  detach: () => void;
}
const __sfc_main = {
  serverCacheKey: (context: Props) => {
    /*
     * Для разных страниц итоговая HTML структура компонента после SSR разная,
     * на страницах продукта структура одно, на остальных другая. Если кешировать не учитывая эту разницу
     * будут возникать ошибки гидратации при открытии главной страницы, если из кэша возьмется
     * категори меню для страницы продукта и вью будет пытаться воссоздать структуру обычной категори меню из
     * категори меню со страницы продукта
     */
    return `category-menu-${context.cityCode}-${context.isProductPage ? 'on-product' : 'default'}`;
  }
};
__sfc_main.props = {
  list: {
    key: "list",
    required: true,
    type: Array
  },
  error: {
    key: "error",
    required: false,
    type: Boolean,
    default: false
  },
  menuState: {
    key: "menuState",
    required: false,
    type: Boolean,
    default: false
  },
  isProductPage: {
    key: "isProductPage",
    required: false,
    type: Boolean,
    default: false
  },
  cityCode: {
    key: "cityCode",
    required: false,
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const defaultMenuTitle = 'Список категорий';
  const mainMenuTitle = ref('Список категорий');
  const mainMenuLink = ref('/catalog');
  const activeIndex = ref<number | undefined>(0);
  const activeCategoriesIndex = ref([0]);
  const showBack = ref(false);
  const shiftList = ref(0);
  let instance: MenuAimInstance | null = null;
  const activeMoreCategories = ref<number[]>([]);
  const activeMobileEl = ref<CategoryMenu>();
  const activeCurrent = ref(false);
  const categoryMenuList = shallowRef<HTMLDivElement>();
  const categoryMenuListWrapper = shallowRef<HTMLDivElement>();
  const router = useRouter();
  const activeNestedListEl = ref<CategoryMenu[]>([]);
  const resizeStore = useResizeStore();
  const isMobile = computed(() => resizeStore.isMobile);
  const isDesktop = computed(() => !resizeStore.isMobile);
  const shiftTransform = computed(() => shiftList.value ? `transform: translateX(-${shiftList.value * 100}%)` : '');
  const borderBottom = computed(() => mainMenuLink.value !== '/catalog' ? 'border-bottom: 1px solid #e0e0e0' : '');
  watch(() => props.menuState, () => {
    bodyOverflow();
    checkWindow();
  });
  onMounted(() => {
    if (!isMobile.value && props.list.length) window.requestAnimationFrame(() => menuAim());
  });
  resizeStore.$onAction(mutation => {
    if (mutation.name === 'setIsMobile') {
      if (mutation.store.isMobile) {
        disableDesktop();
        enableMobile();
      } else {
        disableMobile();
        /* *
        * Если загрузилось дерево категорий, то активируем MenuAim
        */
        menuAim();
      }
      checkWindow();
    }
  });
  onBeforeMount(() => {
    instance?.detach();
  });
  function menuAim() {
    const MenuAim = require('es6-menu-aim');
    instance = new MenuAim.MenuAim(categoryMenuList.value, {
      activate(row: HTMLElement) {
        activeIndex.value = undefined;
        nextTick(() => {
          row.classList.add('category-menu__el_active');
        });
      },
      deactivate(row: HTMLElement) {
        row.classList.remove('category-menu__el_active');
      },
      exitMenu() {
        return false;
      },
      rowSelector: '.category-menu__el_nested'
    });
  }
  function changeActiveIndex(index: number) {
    if (!activeCategoriesIndex.value.includes(index)) activeCategoriesIndex.value.push(index);
    if (!instance) activeIndex.value = index;
  }
  function checkWindow() {
    if (isMobile.value) window.removeEventListener('click', onWindowClick);else if (props.menuState && !isMobile.value) window.requestAnimationFrame(() => window.addEventListener('click', onWindowClick));
  }
  function onWindowClick(evt: Event) {
    const target = (evt.target as HTMLElement);
    const isInsideMenu = target.closest('.category-menu__list');
    if (!isInsideMenu) emit('disable-main-menu');
    window.removeEventListener('click', onWindowClick);
  }
  function onClickShowMoreCategories(id: number) {
    if (!activeMoreCategories.value.includes(id)) activeMoreCategories.value.push(id);
  }
  function bodyOverflow() {
    const type = window.innerWidth <= 800 && props.menuState ? 'add' : 'remove';
    document.body.classList[type]('category-menu__overflow');
  }
  function enableMobile() {
    bodyOverflow();
  }
  function disableMobile() {
    document.body.classList.remove('category-menu__overflow');
    activeCurrent.value = false;
    showBack.value = false;
    shiftList.value = 0;
    resetActiveMobileCategories();
    if (activeMobileEl.value) activeMobileEl.value.isActive = false;
  }
  function close() {
    document.body.classList.remove('category-menu__overflow');
    emit('disable-main-menu');
  }
  function setTitle(el: CategoryMenu) {
    if (el) {
      mainMenuTitle.value = el.name;
      if (el.path) mainMenuLink.value = el.path;
    }
  }
  function enableOnMobile(el: CategoryMenu, visible?: boolean) {
    if (el.children?.length && visible) {
      activeNestedListEl.value.push(el);
      if (activeNestedListEl.value.length === 1) activeCurrent.value = true;
      if (activeNestedListEl.value.length > 1) el.activeCurrent = true;
      shiftList.value++;
      activeMobileEl.value = el;
      el.isActive = true;
      el.isActiveImage = true;
      showBack.value = true;
      setTitle(el);
      if (categoryMenuListWrapper.value) categoryMenuListWrapper.value.scrollTop = 0;
    } else if (!visible || !el.children.length) {
      onNavigatePage(el.path);
    }
  }
  function onNavigatePage(path?: string) {
    if (path) router.push(path);
    hideMobile();
  }
  function backToPrevious() {
    shiftList.value--;
    const el = activeNestedListEl.value.pop();
    activeMobileEl.value = el;
    const arrLength = activeNestedListEl.value.length;
    if (el) el.isActive = false;
    if (arrLength <= 1) activeCurrent.value = false;
    if (arrLength > 1 && el) el.activeCurrent = false;
    setTitle(activeNestedListEl.value[arrLength - 1]);
    if (categoryMenuListWrapper.value) categoryMenuListWrapper.value.scrollTo(pageYOffset, 0);
    if (shiftList.value === 0) {
      showBack.value = false;
      disableDesktop();
    }
  }
  function disableDesktop() {
    mainMenuTitle.value = defaultMenuTitle;
    mainMenuLink.value = '/catalog';
    instance?.detach();
    instance = null;
  }
  function hideMobile() {
    window.removeEventListener('click', onWindowClick);
    emit('disable-main-menu');
    disableMobile();
    mainMenuTitle.value = defaultMenuTitle;
  }
  function resetActiveMobileCategories() {
    activeNestedListEl.value?.forEach(el => el.isActive = false);
    activeNestedListEl.value = [];
  }
  return {
    mainMenuTitle,
    mainMenuLink,
    activeIndex,
    activeCategoriesIndex,
    showBack,
    activeMoreCategories,
    activeCurrent,
    categoryMenuList,
    categoryMenuListWrapper,
    isMobile,
    isDesktop,
    shiftTransform,
    borderBottom,
    changeActiveIndex,
    onClickShowMoreCategories,
    close,
    enableOnMobile,
    onNavigatePage,
    backToPrevious,
    hideMobile
  };
};
export default __sfc_main;
