import { render, staticRenderFns } from "./AuthModal.vue?vue&type=template&id=7b3600dc&scoped=true&"
import script from "./AuthModal.vue?vue&type=script&lang=ts&"
export * from "./AuthModal.vue?vue&type=script&lang=ts&"
import style0 from "./AuthModal.vue?vue&type=style&index=0&id=7b3600dc&lang=postcss&scoped=true&"
import style1 from "./AuthModal.vue?vue&type=style&index=1&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b3600dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/Header/Header.vue').default,InputCode: require('/app/components/InputCode/InputCode.vue').default,UIActionEntity: require('/app/data/UIActionEntity/UIActionEntity.vue').default,Timer: require('/app/components/Timer/Timer.vue').default,InputPhone: require('/app/components/InputPhone/InputPhone.vue').default})
