var render = function () {
  var _vm$authStore$subtitl;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('keep-alive', [_vm.authStore.active ? _c('transition', {
    attrs: {
      "name": "auth-modal-transition",
      "duration": {
        enter: 400,
        leave: 400
      },
      "enter-active-class": "custom-fade-in-up-enter-active",
      "enter-to-class": "custom-fade-in-up-enter-to",
      "leave-class": "custom-fade-out-down-leave",
      "leave-active-class": "custom-fade-out-down-leave-active",
      "leave-to-class": "custom-fade-out-down-leave-to"
    }
  }, [_c('div', {
    staticClass: "auth-modal-overlay",
    on: {
      "mousedown": _vm.onOverlayClick
    }
  }, [_c('div', {
    staticClass: "auth-modal"
  }, [_c('header', {
    staticClass: "auth-modal__head"
  }, [_vm.activeTypeBlock === 'code' ? _c('button', {
    staticClass: "auth-modal__backlink",
    attrs: {
      "type": "button",
      "name": "back-to-phone"
    },
    on: {
      "click": function ($event) {
        _vm.activeTypeBlock = 'phone';
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('h4', {
    staticClass: "h4 auth-modal__title"
  }, [_vm._v("\n            " + _vm._s(_vm.activeTypeBlock === 'code' ? 'Введите код' : 'Авторизация') + "\n          ")]), _vm._v(" "), _c('button', {
    staticClass: "auth-modal__close",
    attrs: {
      "type": "button",
      "name": "close-modal"
    },
    on: {
      "click": function ($event) {
        return _vm.close(false);
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "auth-modal__body"
  }, [_vm.activeTypeBlock === 'code' ? [_c('div', {
    staticClass: "auth-code"
  }, [_c('p', {
    staticClass: "p auth-code__note"
  }, [_vm._v("\n                Код для авторизации отправлен на номер:\n                "), _c('span', {
    staticClass: "auth-code__phone-text"
  }, [_vm._v("\n                  " + _vm._s(_vm.phone) + "\n                ")])]), _vm._v(" "), _c('UIActionEntity', {
    key: "check-code",
    attrs: {
      "get": _vm.$api.auth.checkCodeAndLogin
    },
    on: {
      "success": _vm.onSuccess
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _response$error$descr, _response$error;

        var check = _ref.get,
            error = _ref.error,
            loading = _ref.loading,
            success = _ref.success,
            response = _ref.response;
        return [_c('FormElement', {
          attrs: {
            "error": error ? (_response$error$descr = response === null || response === void 0 ? void 0 : (_response$error = response.error) === null || _response$error === void 0 ? void 0 : _response$error.description) !== null && _response$error$descr !== void 0 ? _response$error$descr : response : null,
            "hint-min-height": "40px"
          },
          scopedSlots: _vm._u([{
            key: "helper",
            fn: function () {
              return [_c('span', {
                staticClass: "auth-code__input-note"
              }, [_vm._v("Не получили код в течение 10 минут? Повторите запрос")])];
            },
            proxy: true
          }], null, true)
        }, [_c('InputCode', {
          ref: "inputCodeRef",
          staticClass: "auth-code__input",
          attrs: {
            "model-value": _vm.code,
            "loading": loading,
            "error": error,
            "success": success,
            "disabled": !_vm.isTermsConfirm
          },
          on: {
            "update:model-value": function ($event) {
              return _vm.onSubmitInputCode($event, check);
            }
          }
        })], 1)];
      }
    }], null, false, 1639456693)
  }), _vm._v(" "), _c('div', {
    staticClass: "auth-code__terms"
  }, [_c('FormElement', {
    attrs: {
      "error": !_vm.isTermsConfirm ? 'Обязательное поле' : null
    }
  }, [_c('Checkbox', {
    staticClass: "terms-checkbox",
    class: [{
      'terms-checkbox_error': !_vm.isTermsConfirm
    }],
    attrs: {
      "checked": _vm.isTermsConfirm
    },
    on: {
      "change": function ($event) {
        _vm.isTermsConfirm = !_vm.isTermsConfirm;
      }
    }
  }, [_vm._v("\n                    Согласен на\n                    "), _c('nuxt-link', {
    staticClass: "link link_blue link_wu",
    attrs: {
      "to": "/about/personal-information/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                      обработку персональных данных\n                    ")]), _vm._v("\n                    в соответствии с\n                    "), _c('nuxt-link', {
    staticClass: "link link_blue link_wu",
    attrs: {
      "to": "/privacy/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                      политикой конфиденциальности\n                    ")]), _vm._v("\n                    и\n                    "), _c('nuxt-link', {
    staticClass: "link link_blue link_wu",
    attrs: {
      "to": "/about/terms-of-use/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                      пользовательским соглашением\n                    ")])], 1)], 1)], 1), _vm._v(" "), _c('UIActionEntity', {
    key: "send-confirm-code",
    attrs: {
      "get": _vm.$api.auth.sendConfirmCode
    },
    on: {
      "success": function ($event) {
        _vm.code = '';
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var sendConfirmCode = _ref2.get;
        return [_c('Timer', {
          attrs: {
            "time": 59
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var time = _ref3.time,
                  reactivate = _ref3.reactivate;
              return [_c('Button', {
                attrs: {
                  "size": "49",
                  "disabled": !_vm.isTermsConfirm || time !== 0,
                  "fluid": "",
                  "name": "get-new-code"
                },
                on: {
                  "click": function ($event) {
                    reactivate(), sendConfirmCode({
                      phone: _vm.phone,
                      short: true
                    });
                  }
                }
              }, [_vm._v("\n                    Получить новый код\n                    "), time !== 0 ? _c('span', {
                staticClass: "ml-5"
              }, [_vm._v("\n                      " + _vm._s(_vm.getTimeSendConfirmCode(time)) + "\n                    ")]) : _vm._e()])];
            }
          }], null, true)
        })];
      }
    }], null, false, 148431910)
  })], 1)] : [_c('div', {
    staticClass: "auth-phone"
  }, [_c('p', {
    staticClass: "p auth-phone__note"
  }, [_vm._v("\n                " + _vm._s((_vm$authStore$subtitl = _vm.authStore.subtitle) !== null && _vm$authStore$subtitl !== void 0 ? _vm$authStore$subtitl : 'Введите номер телефона, и мы отправим код для авторизации') + "\n              ")]), _vm._v(" "), _c('UIActionEntity', {
    attrs: {
      "get": _vm.$api.auth.sendConfirmCode
    },
    on: {
      "success": function ($event) {
        _vm.activeTypeBlock = 'code', _vm.code = '';
      },
      "error": function ($event) {
        return _vm.onErrorSendConfirmCode($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var sendConfirmCode = _ref4.get,
            loading = _ref4.loading,
            error = _ref4.error,
            response = _ref4.response;
        return [_c('ValidationObserver', {
          ref: "phoneObserver"
        }, [_c('Form', {
          attrs: {
            "type": "vertical"
          },
          on: {
            "submit": function ($event) {
              return _vm.onSubmitInputPhone(sendConfirmCode);
            }
          }
        }, [_c('div', {
          staticClass: "auth-phone__input"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|phone",
            "name": "телефон"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var _response$error$descr2, _response$error2;

              var errors = _ref5.errors;
              return [_c('FormElement', {
                attrs: {
                  "error": error ? (_response$error$descr2 = response === null || response === void 0 ? void 0 : (_response$error2 = response.error) === null || _response$error2 === void 0 ? void 0 : _response$error2.description) !== null && _response$error$descr2 !== void 0 ? _response$error$descr2 : response : errors[0],
                  "hint-min-height": "25px"
                }
              }, [_c('InputPhone', {
                ref: "inputPhoneRef",
                attrs: {
                  "model-value": _vm.phone
                },
                on: {
                  "submit": function ($event) {
                    return _vm.onSubmitInputPhone(sendConfirmCode);
                  },
                  "update:model-value": function ($event) {
                    _vm.phone = $event;
                  }
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v;
                  },
                  expression: "phone"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "auth-code__actions"
        }, [loading ? _c('Button', {
          attrs: {
            "theme": "red",
            "size": "49",
            "loading": "",
            "fluid": "",
            "name": "loading-phone-request"
          }
        }) : _c('Button', {
          attrs: {
            "theme": "red",
            "size": "49",
            "fluid": "",
            "name": "send-phone-request"
          }
        }, [_vm._v("\n                        Получить код\n                      ")])], 1)])], 1)];
      }
    }], null, false, 3965469784)
  })], 1)]], 2)])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }