var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    ref: "headerRef",
    staticClass: "header"
  }, [_c('div', [_c('ChooseProject', {
    attrs: {
      "show": _vm.activeChooseProject
    },
    on: {
      "close": function ($event) {
        _vm.activeChooseProject = false;
      }
    }
  }), _vm._v(" "), _c('Business05', {
    attrs: {
      "show": _vm.activeBusiness05
    },
    on: {
      "close": function ($event) {
        _vm.activeBusiness05 = false;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "header__sub-menu"
  }, [_c('div', {
    staticClass: "header__sub-nav"
  }, [_c('div', {
    staticClass: "row-list"
  }, [_vm.currentCityName ? _c('div', {
    staticClass: "header__choose-city",
    class: [{
      'header__choose-city_active': _vm.chooseCityActive
    }],
    style: _vm.chooseCityActive ? "transform: translateX(-110%)" : ''
  }, [_c('span', {
    staticClass: "header__choose-city-link span-button",
    on: {
      "click": function ($event) {
        return _vm.enableChooseCity();
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.currentCityName) + "\n            ")])]) : _vm._e(), _vm._v(" "), _c('nav', {
    staticClass: "header__nav"
  }, _vm._l([{
    name: 'Магазины',
    url: '/about/contacts/'
  }, {
    name: 'Доставка и оплата',
    url: '/about/delivery/'
  }, {
    name: 'Условия возврата',
    url: '/about/guaranty/'
  }, {
    name: 'Бонусы',
    url: '/bonus/',
    class: 'link_bonuses'
  }, {
    name: 'Акции',
    url: '/sales/'
  }, {
    name: 'Бренды',
    url: '/brands/'
  }, {
    name: 'Бизнес с 05.ru',
    onClick: function () {
      return _vm.activeBusiness05 = true;
    }
  }, {
    name: 'Вакансии',
    url: 'https://hr.05.ru/',
    class: 'header__nav-career',
    isExternalLink: true
  }], function (el, index) {
    return _c('div', {
      key: index,
      staticClass: "row-list__el",
      on: {
        "click": function ($event) {
          el.onClick && el.onClick();
        }
      }
    }, [el.isExternalLink ? _c('a', {
      staticClass: "link link_wu",
      class: [el.class],
      attrs: {
        "href": el.url
      }
    }, [_vm._v(" " + _vm._s(el.name))]) : _c(el.url ? 'nuxt-link' : 'span', {
      tag: "component",
      staticClass: "link link_wu",
      class: [el.class],
      attrs: {
        "to": el.url
      }
    }, [_vm._v("\n                " + _vm._s(el.name) + "\n              ")])], 1);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "row-list__el header__row-list-el-phone"
  }, [_c('UIActionEntity', {
    attrs: {
      "get": _vm.$api.feedback.send
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var send = _ref.get,
            error = _ref.error,
            loading = _ref.loading,
            success = _ref.success,
            response = _ref.response;
        return [_c('SmallCallback', {
          attrs: {
            "error": error,
            "loading": loading,
            "success": success,
            "response-error": response
          },
          on: {
            "submit": function ($event) {
              send($event), _vm.emitter.emit('submit-form-data', {
                phone: $event.phone
              });
            }
          }
        })];
      }
    }])
  }), _vm._v(" "), _vm.socialState.telegram ? _c('a', {
    staticClass: "link-icon link-icon_blue link-icon_wu header__phone_social",
    attrs: {
      "href": _vm.socialState.telegram
    }
  }, [_c('div', {
    staticClass: "icon-social-telegram header__phone-icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "link-icon__text header__phone-social"
  }, [_vm._v("Телеграм")])]) : _vm._e(), _vm._v(" "), _vm.socialState.whatsApp ? _c('a', {
    staticClass: "link-icon link-icon_green link-icon_wu header__phone_social",
    attrs: {
      "href": _vm.socialState.whatsApp
    }
  }, [_c('div', {
    staticClass: "icon-social-whatsapp header__phone-icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "link-icon__text header__phone-social"
  }, [_vm._v("WhatsApp")])]) : _vm._e()], 1)])])]), _vm._v(" "), _c('div', {
    staticClass: "header__main-menu"
  }, [_c('nuxt-link', {
    staticClass: "header__logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "header__logo-img",
    attrs: {
      "src": require("@/assets/images/logo-80px.png"),
      "alt": "05.ру интернет-магазин бытовой техники"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "header__button",
    on: {
      "click": function ($event) {
        _vm.activeChooseProject = true;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "header__catalog-button"
  }, [_c('div', {
    staticClass: "header__catalog-wrapper",
    class: [{
      'header__catalog-wrapper_active': _vm.menuState
    }],
    on: {
      "click": function ($event) {
        return _vm.toggleMainMenu();
      }
    }
  }, [_vm._m(0)]), _vm._v(" "), _c('div', {
    staticClass: "header__catalog-menu-hidden"
  }, [_c('LazyHydrate', {
    attrs: {
      "never": "",
      "trigger-hydration": _vm.categoryMenuHydratation
    }
  }, [_c('UIDataEntity', {
    attrs: {
      "params": {
        enhanced: true
      },
      "get": _vm.$api.catalog.categoryMenu
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var response = _ref2.response,
            error = _ref2.error;
        return [_c('CategoryMenu', {
          attrs: {
            "list": response ? response.result.list : [],
            "error": error,
            "menu-state": _vm.menuState,
            "is-product-page": _vm.isProductPage,
            "city-code": _vm.locationStore.code
          },
          on: {
            "disable-main-menu": function ($event) {
              return _vm.disableMainMenu();
            }
          }
        })];
      }
    }])
  })], 1)], 1)]), _vm._v(" "), _c('UIActionList', {
    attrs: {
      "actions": {
        quick: _vm.$api.search.quick,
        popular: _vm.$api.search.popular
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _quick$response, _popular$response;

        var quick = _ref3.quick,
            popular = _ref3.popular;
        return [_c('div', {
          staticClass: "header__search",
          on: {
            "click": _vm.disableMainMenu
          }
        }, [_c('div', {
          ref: "searchWrapper",
          staticClass: "header__search-wrapper"
        }, [_c('SearchBar', {
          attrs: {
            "loading": popular.loading || quick.loading,
            "search-result": ((_quick$response = quick.response) === null || _quick$response === void 0 ? void 0 : _quick$response.result) || ((_popular$response = popular.response) === null || _popular$response === void 0 ? void 0 : _popular$response.result),
            "error": popular.error || quick.error
          },
          on: {
            "focus": popular.request,
            "input": quick.request,
            "select-product": function ($event) {
              _vm.$router.push(String($event.path));
            }
          }
        })], 1)])];
      }
    }])
  }), _vm._v(" "), _c('div', {
    staticClass: "header__phone"
  }, [_vm.socialState.phone ? _c('div', {
    staticClass: "header__phone-top"
  }, [_c('a', {
    staticClass: "h5 link link_wu header__phone-link",
    attrs: {
      "href": ("tel:" + (_vm.formatPhone))
    }
  }, [_vm._v("\n            " + _vm._s(_vm.socialState.phone) + "\n          ")])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "header__phone-bottom"
  }, [_c('UIActionEntity', {
    attrs: {
      "get": _vm.$api.feedback.send
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var send = _ref4.get,
            error = _ref4.error,
            loading = _ref4.loading,
            success = _ref4.success,
            response = _ref4.response;
        return [_c('SmallCallback', {
          staticClass: "header__small-callback",
          attrs: {
            "error": error,
            "loading": loading,
            "success": success,
            "response-error": response,
            "data-e2e": "callback-pop-up"
          },
          on: {
            "submit": function ($event) {
              send($event), _vm.emitter.emit('submit-form-data', {
                phone: $event.phone
              });
            }
          }
        })];
      }
    }])
  }), _vm._v(" "), _vm.socialState.telegram ? _c('a', {
    staticClass: "link-icon link-icon_blue link-icon_wu header__phone_social",
    attrs: {
      "href": _vm.socialState.telegram
    }
  }, [_c('div', {
    staticClass: "icon-social-telegram header__phone-icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "link-icon__text header__phone-social"
  }, [_vm._v("Телеграм")])]) : _vm._e(), _vm._v(" "), _vm.socialState.whatsApp ? _c('a', {
    staticClass: "link-icon link-icon_green link-icon_wu header__phone_social",
    attrs: {
      "href": _vm.socialState.whatsApp
    }
  }, [_c('div', {
    staticClass: "icon-social-whatsapp header__phone-icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "link-icon__text header__phone-social"
  }, [_vm._v("WhatsApp")])]) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
    staticClass: "header__city"
  }, [_c('div', {
    staticClass: "header__city-text grey-text"
  }, [_vm._v("\n          Город\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "header__city-name p"
  }, [_c('span', {
    staticClass: "header__city-name-text",
    on: {
      "click": function ($event) {
        return _vm.enableChooseCity();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentCityName) + " ")])])]), _vm._v(" "), _c('div', {
    staticClass: "header__user-panel"
  }, [_c('UserPanel')], 1)], 1), _vm._v(" "), _c('UserPanelMobile', {
    attrs: {
      "menu-state": _vm.menuState
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMainMenu();
      },
      "click-support": function ($event) {
        return _vm.onClickSupport();
      },
      "disable": function ($event) {
        return _vm.disableMainMenu();
      }
    }
  }), _vm._v(" "), _c('SupportModal', {
    attrs: {
      "is-active": _vm.isSupportModalActive,
      "phone": _vm.socialState.phone,
      "socials": {
        whatsApp: _vm.socialState.whatsApp,
        telegram: _vm.socialState.telegram,
        email: _vm.socialState.email
      }
    },
    on: {
      "close": function ($event) {
        return _vm.closeSupportModal();
      }
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header__catalog"
  }, [_c('div', {
    staticClass: "header__catalog-icon-wrap"
  }, [_c('div', {
    staticClass: "header__catalog-icon"
  }, [_c('span'), _vm._v(" "), _c('span'), _vm._v(" "), _c('span'), _vm._v(" "), _c('span')])])]);
}]

export { render, staticRenderFns }