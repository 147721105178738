var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "max-width": "514px",
      "show": _vm.show
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h3', {
          staticClass: "choose-project__title"
        }, [_vm._v("\n      Наши проекты\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "choose-project__list"
  }, [_c('nuxt-link', {
    staticClass: "choose-project__item",
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "choose-project__item-col"
  }, [_c('div', {
    staticClass: "choose-project__item-title"
  }, [_vm._v("\n          Интернет-магазин\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "choose-project__item-desc"
  }, [_vm._v("\n          Электроника и бытовая техника\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "choose-project__item-col"
  }, [_c('div', {
    staticClass: "choose-project__item-logo"
  }, [_c('img', {
    staticClass: "choose-project__item-img",
    attrs: {
      "src": require("@/assets/images/logo.png"),
      "alt": "05.ру интернет-магазин бытовой техники"
    }
  })])])]), _vm._v(" "), _c('a', {
    staticClass: "choose-project__item",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_logo_05&utm_medium=referral&utm_campaign=05")
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "choose-project__item-col"
  }, [_c('div', {
    staticClass: "choose-project__item-title"
  }, [_vm._v("Близко")]), _vm._v(" "), _c('div', {
    staticClass: "choose-project__item-desc"
  }, [_vm._v("Доставка продуктов за 30 минут")])]), _vm._v(" "), _c('div', {
    staticClass: "choose-project__item-col"
  }, [_c('div', {
    staticClass: "choose-project__item-logo"
  }, [_c('img', {
    staticClass: "choose-project__item-img",
    attrs: {
      "src": require("@/assets/images/choose-project/logo.svg"),
      "alt": "05.ру DarkStore доставка продуктов"
    }
  })])])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }