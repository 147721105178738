var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "viewport-toogle"
  }, [_c('div', {
    staticClass: "viewport-toogle__desktop"
  }, [_vm._t("desktop")], 2), _vm._v(" "), _c('div', {
    staticClass: "viewport-toogle__mobile"
  }, [_vm._t("mobile")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }